<template>
  <div :style="this.$vuetify.theme.dark ? 'background-color: #1E1E1E;' : ''" class="mb-3 card" style="height: 450px;">
    <div :class="[this.$vuetify.theme.dark ? 'dark-bg' : '']" class="card-header-tab card-header">
      <div class="card-header-title font-size-lg text-capitalize font-weight-normal">
        <i class="header-icon lnr-cloud-download icon-gradient bg-happy-itmeo"> </i>
        {{ this.$t('generic.lang_gui_notifications') }}
      </div>
    </div>
    <div class="p-0 card-body">

      <tabs :currentTab="currentTab" :line-class="'tab-item-line'" :tab-active-class="'tab-item-active'"
        :tab-class="'tab-item'" :tabs="tabs" :wrapper-class="'shadow-tabs inline-tabs'" @onClick="handleClick" />
      <v-progress-linear indeterminate v-if="this.loading" />
      <div v-if="currentTab === 'versionInfo'" style="overflow: auto;max-height: 100%;">
        <div class="scroll-area-md" style="height: 330px !important;">
          <div class="scrollbar-container">
            <div class="p-3">
              <v-card elevation="0" class="pa-2 " :loading="loading">
                <div id="barcode" class="card elevation-0 transparent mt-4">
                  <h6 class="text-muted">{{ this.$t('help.lang_helpYourSystem') }}</h6>

                  <strong class="primary--text">3POS ID: {{ api.auth.posID }}</strong>
                </div>
                <div class="card elevation-0 transparent mt-4">
                  <h6 class="text-muted">{{ this.$t('help.lang_version') }}:</h6>
                  <strong class="primary--text">{{ appVersion }}</strong>
                  <div class="text-muted font-weight-bold my-2">
                    {{ this.$t('help.lang_version') }} <span class="primary--text">{{ changeLog.version + ' ' }}</span>
                    {{ $t('generic.lang_from') }} {{ ' ' + changeLog.date }}
                    <a href="https://changelog.3pos.de" class="text-decoration-none"
                      target="_blank">{{ $t('generic.lang_more') }}<v-icon color="primary">mdi-open-in-new</v-icon></a>
                  </div>
                  <p class="subheading font-weight-regular mb-3">
                    <v-badge color="primary" :content="$t('generic.lang_change')" inline></v-badge>
                  <ol class="mt-2 pl-8">
                    <li v-for="(text, index) in getLogList(changeLog.changes)" :key="index" class="pa-1">{{ text }}</li>
                  </ol>
                  </p>

                  <p class="subheading font-weight-regular mb-3">
                    <v-badge color="success" :content="$t('generic.lang_feature')" inline></v-badge>
                  <ol class="mt-2 pl-8">
                    <li v-for="(text, index) in getLogList(changeLog.features)" :key="index" class="pa-1">{{ text }}</li>
                  </ol>
                  </p>

                  <p class="subheading font-weight-regular mb-3">
                    <v-badge color="warning" :content="$t('generic.lang_improvement')" inline></v-badge>
                  <ol class="mt-2 pl-8">
                    <li v-for="(text, index) in getLogList(changeLog.optimizations)" :key="index" class="pa-1">{{ text }}
                    </li>
                  </ol>
                  </p>

                  <p class="subheading font-weight-regular mb-3">
                    <v-badge color="error" :content="$t('generic.lang_bug')" inline></v-badge>
                  <ol class="mt-2 pl-8">
                    <li v-for="(text, index) in getLogList(changeLog.bugs)" :key="index" class="pa-1">{{ text }}</li>
                  </ol>
                  </p>
                </div>
              </v-card>
            </div>
          </div>
        </div>
      </div>
      <div :class="[this.$vuetify.theme.dark ? '' : 'scroll-gradient']" v-if="currentTab === 'stockStatus'">
        <div class="scroll-area-md">
          <div class="scrollbar-container">
            <div class="p-3">
              <div
                class="vertical-time-simple vertical-without-time vertical-timeline vertical-timeline--animate vertical-timeline--one-column">
                <div :key="i + 'vertical-1'" class="vertical-timeline-item vertical-timeline-element"
                  v-for="(item, i) in this.items">
                  <div><span class="vertical-timeline-element-icon bounce-in"></span>
                    <div class="vertical-timeline-element-content bounce-in" style="min-width: 200px !important;">
                      <h4 class="timeline-title">
                        {{ item.ware_name }} (<span class="text-danger">{{ Math.floor(item.min) }}</span>)</h4><span
                        class="text-primary">{{
                          item.storage_name
                        }}</span>
                    </div>
                  </div>
                </div>
                <div :key="i + 'vertical-2'" class="vertical-timeline-item vertical-timeline-element"
                  v-for="(item, i) in this.items">
                  <div><span class="vertical-timeline-element-icon bounce-in"></span>
                    <div class="vertical-timeline-element-content bounce-in" style="min-width: 200px !important;">
                      <h4 class="timeline-title">
                        {{ item.ware_name }} (<span class="text-danger">{{ Math.floor(item.min) }}</span>)</h4><span
                        class="text-primary">{{
                          item.storage_name
                        }}</span>
                    </div>
                  </div>
                </div>
                <div :key="i + 'vertical-3'" class="vertical-timeline-item vertical-timeline-element"
                  v-for="(item, i) in this.items">
                  <div><span class="vertical-timeline-element-icon bounce-in"></span>
                    <div class="vertical-timeline-element-content bounce-in" style="min-width: 200px !important;">
                      <h4 class="timeline-title">
                        {{ item.ware_name }} (<span class="text-danger">{{ Math.floor(item.min) }}</span>)</h4><span
                        class="text-primary">{{
                          item.storage_name
                        }}</span>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
      <ul class="nav flex-column">
        <!--- <li class="nav-item-btn text-center pt-1 pb-3 nav-item">
          <button class="btn-shadow btn-wide btn-pill btn btn-dark">
            <span class="badge badge-dot badge-dot-lg badge-warning badge-pulse">Badge</span>
            View All Messages
          </button>
        </li> --->
      </ul>
    </div>
  </div>
</template>

<script>
import Tabs from 'vue-tabs-with-active-line';
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { ENDPOINTS } from "@/config";
import { Events } from "@/plugins/events";
import { mapState } from "vuex"

export default {
  name: "NotificationComponent",
  components: { Tabs, VuePerfectScrollbar },
  data() {
    return {
      items: [],
      changeLog: null,
      loading: false,
      tabs: [{
        title: this.$t('generic.lang_stockStatus'),
        value: 'stockStatus',
      },
      {
        title: this.$t('generic.lang_version_info'),
        value: 'versionInfo',
      }],
      currentTab: 'stockStatus',
    }
  },
  computed: {
    ...mapState([
      'api'
    ]),
    appVersion() {
      return process.env.VUE_APP_VERSION + " (" + process.env.VUE_APP_VERSION_DATETIME + ")";
    }
  },
  mounted() {
    this.loadOutOfStock()
    this.getChangeLog()
  },
  methods: {
    loadOutOfStock() {
      this.loading = true;
      this.axios.post(ENDPOINTS.DASHBOARD.OUTOFSTOCK).then((res) => {
        if (res.data.status) {
          this.items = res.data.items
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t("generic.lang_errorOccurred"),
            color: "error"
          });
        }
      }).catch((err) => {
        Events.$emit("showSnackbar", {
          message: err.message,
          color: "error"
        });
      }).finally(() => {
        this.loading = false;
      })
    },
    handleClick(newTab) {
      this.currentTab = newTab;
    },
    getChangeLog() {
      let axios = this.axios.create();
      //REMOVE 3POS HEADERS
      delete axios.defaults.headers.common['3POSAPITOKEN'];
      delete axios.defaults.headers.common['3POSBS'];
      delete axios.defaults.headers.common['3POSBSID'];

      this.loading = true;
      axios.get("https://devapi.3pos.de/changelog/?limit=1").then(res => {
        if (res.status === 200 && res.data) {
          this.changeLog = res.data[0] || null;
        }
      }).catch(err => {
        console.log(err);
      }).finally(() => {
        this.loading = false;
      })
    },
    getLogList(log) {
      return log.split('@@').filter(v => v !== '');
    }
  },
}
</script>

<style scoped>
.dropdown-menu-header {
  z-index: 2 !important;
}

.shadow-tabs {
  z-index: 2 !important;
}

.scroll-gradient {
  z-index: 2 !important;
}

.apexcharts-toolbar {
  z-index: 2 !important;
}
</style>